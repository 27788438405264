<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <v-form ref="form" v-model="isValid">
          <card-title icon="/images/icons/elimu-b.png"
            >Declaration Form
          </card-title>
          <back-nav to="ProposalSummary" />
          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 tw-w-full py-7 px-5 my-5">
            <v-card class="accent">
            <h2 class="pl-4 mt-7 tw-font-semibold">
              AUTHORISATION TO OBTAIN INFORMATION
            </h2>
            <v-card-text>
              <p class="tw-text-base tw-mt-2">
                Personal data refers to all information that may directly or
                indirectly identify you. In order to provide you with products
                and services, we need to collect, use, share and store your
                personal data. This may include information provided by you or
                obtained from third parties. The information may be used to
                assist us in providing the service you are applying for and
                shall be used in fulfilment of contractual obligations. We may
                also use the information to advise you of other products and
                services provided by us, to confirm, update and enhance records
                and to establish your identity. The data collected may be shared
                / transferred /stored /processed within or outside the Kenyan
                jurisdiction. Any reference to "We" or "Us" will mean Apollo
                Group. Refer to our website www.apainsurance.org to see the
                entities under Apollo Group.
              </p>
            </v-card-text>
            <v-checkbox
              v-model="checkbox"
              required
              :rules="[check()]"
              class="pl-4 primary--text"
            >
              <template v-slot:label>
                <div class="tw-font-semibold primary--text">
                  I authorise APA Life Assurance to obtain and use my personal
                  information as per the above
                </div>
              </template>
            </v-checkbox>
            <p class="pl-12 tw-text-xs">
              Note: in case you would like to revoke the consent kindly send an
              email to privacy@apollo.co.ke
            </p>
          </v-card>
          <v-card class="accent">
            <h2 class="pl-4 tw-font-semibold pt-7 pb-5">Declaration Form</h2>
            <ol class="pl-7 tw-list-disc">
              <li class="py-2">
                I/We understand that the statements and all number information
                provided in this application form, whether in my/our own
                handwriting or not, are complete and true to the best of my/our
                knowledge and that it will form part of the policy.
              </li>
              <li class="py-2">
                Change in amount, classification or benefits shall be effective
                unless agreed to in writing by the policy owner.
              </li>
              <li class="py-2">
                It is also agreed that APA Life will incur no liability under
                this application until, the application has been received and
                approved and the premium has been paid and accepted by APA Life.
              </li>
              <li class="py-2">
                I/We understand that no intermediary has the authority to waive
                the answers to any of the question in this application or to
                make or alter any contract for APA Life Assurance.
              </li>
              <li class="py-2">
                I/We declare that I/We am/are in good health and free from
                disease or disability or any other symptoms thereof, unless
                otherwise stated above under Section 4 of this form.
              </li>
              <li class="py-2">
                I/We consent to receive the policy document via the E-Mail
                address provided in Section 1 above and I/We also understand
                that the policy document will be considered delivered once dispatched to the email address.
              </li>
            </ol>
          </v-card>
          </div>
          <v-checkbox
            v-model="checkbox2"
            :rules="[check()]"
            class="pl-4 primary--text"
          >
            <template v-slot:label>
              <div class="tw-font-semibold primary--text">
                I confirm that i’ve read and understood the Terms and conditions
                of the product. Verify sign off with OTP code sent to your client's email or mobile
              </div>
            </template>
          </v-checkbox>
          <div class="container tw-w-full tw-py-3 md:tw-py-6">
            <div class="tw-flex tw-flex-wrap">
              <v-btn
                class="secondary tw-w-64 mx-auto tw-my-8"
                large
                @click="proceedToOTP"
                :disabled="!isValid"
                >Proceed to Payment</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import BackNav from '../BackNav';
import validations from '@/utils/validations';

export default {
  name: 'DeclarationForm',
  metaInfo: {
    title: 'Buy Cover - Declaration Form',
  },
  components: {
    CardTitle,
    BackNav,
  },
  data() {
    return {
      pageLoading: false,
      isValid: false,
      ...validations,
      form: {
        optionSelected: '',
      },
      checkbox: null,
      checkbox2: null,
      menu: false,
    };
  },

  computed: {
    ...mapState({
      clientCodeState: (state) => state.quote.clientCode,
      quoteCodeState: (state) => state.quote.quoteCode,
      clientIdState: (state) => state.quote.clientId,
    }),
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'declaration_page_visited_by_agent', {
        event_category: 'Declaration form page component created',
        event_label: 'declaration form page component created',
      });
    }
  },

  methods: {
    proceedToOTP() {
      this.pageLoading = true;
      axios
        .post(
          `/lmsagent/quotations/generateOtpRequest?QuoteCode=${this.quoteCodeState}`,
        )
        .then((res) => {
          this.pageLoading = false;
          if (res.data.responseStatus.description === 'succeeded') {
            this.$emit('to-otp-confirmation');
          } else {
            this.error = res.data.responseStatus.description;
          }
        });
    },

  },
};
</script>
