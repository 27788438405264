<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <top-card current="PersonalDetails" cover="Elimu Plan" :amount="5"
        >Kindly provide some additional details before you proceed</top-card
      >

      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="/images/icons/elimu-b.png"
          >Personal Details
        </card-title>
        <back-nav to="PersonalDetails" />
        <v-form v-model="isFormValid">
          <div class="tw-px-4 md:tw-px-12 tw-mt-4 md:tw-mt-10">
            <div class="tw-w-full">
              <h3
                class="
                  tw-text-base
                  md:tw-text-lg
                  tw-px-2
                  md:tw-px-8
                  tw-mt-3
                  md:tw-mt-10
                  pb-10
                "
              >
                Please Provide the information of
                <span class="c-blue-text">Policy Owner</span>
                before proceeding.
              </h3>
            </div>
            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
              <v-select
                class="md:tw-mx-5"
                :items="title"
                label="Title"
              ></v-select>
              <v-text-field
                v-model="ownerForm.surname"
                class="my-2 tw-px-0 md:tw-px-6"
                label="Surname"
                type="text"
                :rules="[required('Surname')]"
              ></v-text-field>
              <v-text-field
                v-model="ownerForm.othernames"
                class="my-2 tw-px-0 md:tw-px-6"
                label="Other Names"
                type="text"
                :rules="[required('Other Names')]"
              ></v-text-field>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="ownerForm.dateOfBirth"
                    persistent-hint
                    readonly
                    label="Date Of Birth"
                    v-bind="attrs"
                    v-on="on"
                    class="md:tw-mx-5"
                    :rules="[required('Date of Birth')]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :active-picker.sync="activePicker"
                  v-model="ownerForm.dateOfBirth"
                  no-title
                  @input="menu = false"
                  :max="givenYearsAgo(18)"
                  :min="givenYearsAgo(65)"
                  color="primary"
                ></v-date-picker>
              </v-menu>
              <v-select id="nationality" v-model="ownerForm.nationality" class="md:tw-mx-5" :items="nationalities" label="Nationality"
                :rules="[required('Nationality')]">
              </v-select>
              <vue-tel-input-vuetify
                v-bind="phoneNumberBindProps"
                v-model="phoneNumber"
                class="my-2 tw-px-0 md:tw-px-6"
                :rules="[required('Mobile No.')]"
                label="Mobile No."
              />
              <v-text-field
                v-model="ownerForm.email"
                class="my-2 tw-px-0 md:tw-px-6"
                label="Email"
                type="text"
                :rules="[required('Email'), emailFormat()]"
              ></v-text-field>
              <v-select
                v-model="ownerForm.maritalStatus"
                class="md:tw-mx-5"
                :items="maritalStatusList"
                label="Marital Status"
                :rules="[required('Marital Status')]"
              ></v-select>
              <v-select
                  id="occupation"
                  v-model="ownerForm.otherOccupation"
                  class="my-2 tw-px-0 md:tw-px-6"
                  label="Occupation"
                  :items="occupationList"
                  type="text"
                  :rules="[required('occupation')]"
                ></v-select>
              <v-text-field
                v-if="activateOccupation"
                id="otherOccupation"
                v-model="writeOccupation"
                class="my-2 tw-px-0 md:tw-px-6"
                label="Write Occupation"
                type="text"
                :rules="[required('occupation description')]"
              ></v-text-field>
              <v-text-field
                v-model="ownerForm.nameOfEmployer"
                class="my-2 tw-px-0 md:tw-px-6"
                label="Name of Employer / Business "
                type="text"
                :rules="[required('Name Of Employer / Business')]"
              ></v-text-field>
              <v-text-field
                v-model="ownerForm.residence"
                class="my-2 tw-px-0 md:tw-px-6"
                label="Residential Address"
                type="text"
                :rules="[required('Residential Address')]"
              ></v-text-field>
              <v-text-field
                v-model="ownerForm.kraPinNumber"
                class="my-2 tw-px-0 md:tw-px-6"
                label="KRA PIN"
                type="text"
                :rules="[required('KRA Pin Number'), kraPinFormat()]"
              ></v-text-field>
              <v-text-field
                v-model="ownerForm.idNumber"
                class="my-2 tw-px-0 md:tw-px-6"
                label="ID/Passport Number"
                type="text"
                :rules="[required('ID/Passport Number')]"
              ></v-text-field>
              <div class="grid grid-cols-3 my-6 tw-px-0 md:tw-px-6">
              <h4 class="c-blue-text">Gender</h4>
              <v-radio-group
                v-model="ownerForm.gender"
                class="my-2 tw-px-0 md:tw-px-6"
                :rules="[required('Gender')]"
                row
              >
                <v-radio
                  label="Male"
                  value="MALE"
                  class="tw-mt-0 md:tw-mt-5 tw-pr-10"
                ></v-radio>
                <v-radio
                  label="Female"
                  value="FEMALE"
                  class="tw-mt-0 md:tw-mt-5"
                ></v-radio>
              </v-radio-group>
              </div>
              <div v-if="quoteSumState >= 1000000" class="tw-w-full">
                <h3
                  class="
                    c-blue-text c-title
                    tw-text-base
                    md:tw-text-lg
                    tw-px-2
                    md:tw-px-8
                    tw-mt-3
                    md:tw-mt-10
                  "
                >
                  Please select source of funds
                </h3>
                <div class="tw-mt-2 tw-ml-8">
                    <v-checkbox id="check" label="Source of Funds" v-model="isChecked" :rules="[check()]"></v-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="container tw-w-full tw-py-3 md:tw-py-6">
            <div class="tw-flex tw-flex-wrap">
              <v-btn
                id="ownerSubmit"
                class="secondary tw-w-64 mx-auto tw-my-8"
                large
                @click="policyOwnerSubmission"
                :disabled="!isFormValid"
                :loading="loading"
                >Continue</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>
      <v-dialog v-model="isSource" persistent>
        <v-card
        elevation="2"
        class="tw-px-2 md:tw-px-6 tw-mt-5 md:tw-mt-10 mx-auto"
        max-width="600px"
      >
        <card-title :icon="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')">
          Source of Funds
        </card-title>
        <div class="tw-w-full tw-text-center">
          <h2 class="tw-font-semibold pt-7 pb-5">{{ scanObject(currentProduct, 'title') }}</h2>
          <p class="pb-5">
            Please confirm the source of funds for this investment
          </p>
          <v-form class="pt-5" ref="form" v-model="isValid">
              <v-select
                id="maritalStatus"
                v-model="sourceOfFunds"
                class="md:tw-mx-5"
                :items="sourceOfFundsList"
                label="Source Of Funds"
                :rules="[required('Source Of Funds')]"
              ></v-select>
          </v-form>
        </div>

        <div class="container tw-w-full md:tw-py-6">
          <div class="tw-flex tw-flex-wrap">
            <v-btn
            id="confirmSource"
              class="secondary tw-w-64 mx-auto tw-my-8"
              @click="confirmSource"
              large
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </div>
        </div>
      </v-card>
      </v-dialog>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import BackNav from '../BackNav';

import validations from '@/utils/validations';
import { formatDate, givenYearsAgo } from '@/utils/time';
import { nationalityList } from '@/utils/nationalities';

export default {
  name: 'OwnerDetails',
  metaInfo: {
    title: 'Buy Cover - Owners Details',
  },
  components: {
    CardTitle,
    TopCard,
    BackNav,
  },

  data() {
    return {
      writeOccupation: null,
      otherOccupation: false,
      sourceOfFunds: null,
      isChecked: false,
      clientIdentity: '',
      date: '',
      title: ['Mr', 'Mrs', 'Miss', 'Prof', 'Doc'],
      maritalStatusList: ['Single', 'Married', 'Divorced', 'Widowed'],
      occupation: null,
      occupationList: [
        'ACCOUNTANT', 'ADMINISTRATOR', 'BANKER', 'BUSINESS PERSON', 'CIVIL SERVANT', 'DRIVER', 'ENGINEER', 'FARMER', 'HEALTH/MEDICAL PRACTITIONER',
        'INSURANCE OFFICER', 'LAWYER', 'MECHANIC', 'PILOT', 'POLICE OFFICER', 'SALES PERSON/MARKETER',
        'SECURITY OFFICER', 'SOLDIER', 'STUDENT', 'TEACHER/LECTURER', 'TECHNICIAN', 'Other',
      ],
      activateOccupation: false,
      pageLoading: false,
      isSource: false,
      isValid: false,
      menu: false,
      ownerForm: {
        surname: '',
        othernames: '',
        email: '',
        dateOfBirth: '',
        nationality: 'Kenyan',
        otherOccupation: '',
      },
      ...validations,
      isFormValid: false,
      activePicker: null,
      loading: false,
      phoneNumber: null,
      nationalities: nationalityList(),
      sourceOfFundsList: [
        'Investment Income',
        'Gift or Inheritance',
        'Loan',
        'Sale of Investment Porfolio/Property',
        'Pension',
        'Salary/Savings',
      ],
    };
  },
  watch: {
    isChecked() {
      if (this.isChecked === true) {
        this.isSource = true;
      } else {
        this.isSource = false;
      }
    },
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    phoneNumber() {
      this.ownerForm.mobileNumber = this.phoneNumber.replace(/\s/g, '');
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    occupation() {
      this.ownerForm.otherOccupation = this.occupation;
      if (this.occupation && this.occupation === 'Other') {
        this.activateOccupation = true;
      } else {
        this.activateOccupation = false;
      }
    },
    writeOccupation() {
      this.ownerForm.otherOccupation = this.writeOccupation;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.form.coverFrom || this.formatDate(this.date);
    },
    ...mapState({
      quoteSumState: (state) => state.userPersonalData.personalData.amount,
    }),
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'capture_owner_dtls_page_visited_by_agent', {
        event_category: 'capture owner details component created',
        event_label: 'capture owner details component created',
      });
    }
  },
  methods: {
    policyOwnerSubmission() {
      this.loading = true;
      this.$store.commit('quote/policyOwnerInfo', this.ownerForm);
      this.$emit('to-beneficiaries');

      this.loading = false;
    },
    confirmSource() {
      if (this.sourceOfFunds) {
        this.$store.commit('quote/sourceOfFundsInfo', this.sourceOfFunds);
        this.isSource = false;
      } else {
        this.isSource = true;
      }
    },
    formatDate,
    givenYearsAgo,
  },
};
</script>
