<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <top-card current="HealthQuestionnaire" cover="Elimu Plan" :amount="5"
        >Kindly provide some additional details before you proceed</top-card
      >

      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <v-form ref="form" v-model="valid">
          <card-title icon="/images/icons/elimu-b.png"
            >Health Questionnaire
          </card-title>
          <back-nav to="Beneficiaries" />
          <div v-if="getProductCode === ippProductCode">
            <v-banner
                name="input-71"
                lines="one"
                icon="mdi-lightbulb"
                color="blue"
                class="tw-mt-4"
              >
                <v-banner-text
                class="white--text tw-text-lg">
                   Kindly note this part is not required. Click continue button below to proceed.
                </v-banner-text>
              </v-banner>
          </div>
          <div v-else class="md:tw-w-3/4 tw-mt-5 tw-pl-5">
            <v-card-text class="tw-font-normal">
              Please provide complete details on the section below and/or
              disclose any ailments and /or conditions your client may have had
              in the past 3 years. Additional information or reports should be
              attached where required.
            </v-card-text>

            <div class="tw-py-5 mt-7">
              <h2 class="tw-font-semibold">a) Are you in good health?</h2>
              <v-radio-group
                v-model="formAnswer.goodHealth"
                class="tw-px-0 pt-4 md:tw-px-6"
                :rules="[required('')]"
                row
              >
                <v-radio
                  label="Yes"
                  value="Y"
                  class="md:tw-mt-5 tw-pr-7"
                ></v-radio>
                <v-radio
                  label="No"
                  value="N"
                  class="tw-mt-0 md:tw-mt-5"
                ></v-radio>
              </v-radio-group>
              <div v-if="formAnswer.goodHealth === 'N'">
                <p class="caption primary--text pl-2 pt-2">
                  NOTE: Include treatment, dates of treatment, medical
                  institution, where treated and name of treating doctor
                </p>
                <v-textarea
                  v-model="formAnswer.goodHealthDetails"
                  name="input-7-1"
                  filled
                  label="Please explain"
                  class="my-0"
                  auto-grow
                ></v-textarea>
              </div>
            </div>
            <div class="tw-py-5 mt-7">
              <h2 class="tw-font-semibold">
                b) Do you have any known medical Condition?
              </h2>
              <v-radio-group
                v-model="formAnswer.medicalCondition"
                class="tw-px-0 pt-4 md:tw-px-6"
                :rules="[required('')]"
                row
              >
                <v-radio
                  label="Yes"
                  value="Y"
                  class="md:tw-mt-5 tw-pr-7"
                ></v-radio>
                <v-radio
                  label="No"
                  value="N"
                  class="tw-mt-0 md:tw-mt-5"
                ></v-radio>
              </v-radio-group>
              <div v-if="formAnswer.medicalCondition === 'Y'">
                <p class="caption primary--text pl-2 pt-2">
                  NOTE: Include treatment, dates of treatment, medical
                  institution, where treated and name of treating doctor
                </p>
                <v-textarea
                  v-model="formAnswer.medicalConditionDetails"
                  name="input-7-1"
                  filled
                  label="Please explain"
                  class="my-0"
                  auto-grow
                ></v-textarea>
              </div>
            </div>
            <div class="tw-py-5 mt-7">
              <h2 class="tw-font-semibold">
                c) Are you currently taking any medication regularly or as
                required?
              </h2>
              <v-radio-group
                v-model="formAnswer.medication"
                class="tw-px-0 pt-4 md:tw-px-6"
                :rules="[required('')]"
                row
              >
                <v-radio
                  label="Yes"
                  value="Y"
                  class="md:tw-mt-5 tw-pr-7"
                ></v-radio>
                <v-radio
                  label="No"
                  value="N"
                  class="tw-mt-0 md:tw-mt-5"
                ></v-radio>
              </v-radio-group>
              <div v-if="formAnswer.medication === 'Y'">
                <p class="caption primary--text pl-2 pt-2">
                  NOTE: Include treatment, dates of treatment, medical
                  institution, where treated and name of treating doctor
                </p>
                <v-textarea
                  v-model="formAnswer.medicationDetails"
                  name="input-7-1"
                  filled
                  label="Please explain"
                  class="my-0"
                  auto-grow
                ></v-textarea>
              </div>
            </div>
            <h2 class="tw-font-semibold tw-pt-4">d). Height (cm)</h2>
            <v-text-field
              v-model="formAnswer.height"
              class="my-4 tw-px-0 md:tw-px-6 md:tw-w-1/3"
              label="Input Height"
              type="number"
              precision="1"
              :rules="[required('Height')]"
            ></v-text-field>
            <h2 class="tw-font-semibold tw-pt-4">e). Weight (kg)</h2>
            <v-text-field
              v-model="formAnswer.weight"
              class="my-4 tw-px-0 md:tw-px-6 md:tw-w-1/3"
              label="Input Weight"
              type="number"
              :rules="[required('Weight')]"
            ></v-text-field>
            <h2 class="tw-font-semibold tw-pt-7">
              f). Any other additional Information, add below
            </h2>
            <v-radio-group
              v-model="formAnswer.additionalInfo"
              class="tw-px-0 pt-4 md:tw-px-6"
              :rules="[required('')]"
              row
            >
              <v-radio
                label="Yes"
                value="Y"
                class="md:tw-mt-5 tw-pr-7"
              ></v-radio>
              <v-radio
                label="No"
                value="N"
                class="tw-mt-0 md:tw-mt-5"
              ></v-radio>
            </v-radio-group>
            <div v-if="formAnswer.additionalInfo === 'Y'">
              <v-textarea
                v-model="formAnswer.additionalInfoDetails"
                name="input-7-1"
                filled
                label="Write Here"
                class="my-0"
                auto-grow
              ></v-textarea>
            </div>
          </div>
          <div class="tw-w-full tw-py-3 md:tw-py-6">
            <div class="tw-flex tw-flex-wrap">
              <v-btn
                class="secondary tw-w-64 mx-auto tw-my-8"
                large
                :loading="healthLoading"
                :disabled="!valid"
                @click="submitInfo"
                >Continue</v-btn
              >
            </div>
          </div>
        </v-form>

        <existing-policies
          :existingPolicyDialog="existingPolicyDialog"
          @activate-lien-form="activateLien"
          @close-dialog-form="closeDialog"
        />
      </v-card>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import ExistingPolicies from '@/components/buy/ExistingPolicies';
import BackNav from '../BackNav';
import kycCollectionMixins from '@/mixins/kycCollectionMixins';

import validations from '@/utils/validations';

export default {
  name: 'HealthQuestionnaire',
  mixins: [kycCollectionMixins],
  metaInfo: {
    title: 'Buy Cover - Health Questionnaire',
  },
  components: {
    CardTitle,
    TopCard,
    ExistingPolicies,
    BackNav,
  },
  data() {
    return {
      date: '',
      pageLoading: false,
      healthLoading: false,
      isValid: false,
      valid: false,
      menu: false,
      dialog: false,
      existingPolicyDialog: false,
      beneficiaryStatus: false,
      formAnswer: {},
      additionalInfo: {},
      ...validations,
      healthQuestions: [
        {
          question: 'a) Are you in good health?',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'no', value: 'No' },
          ],
        },
        {
          question: 'b) Do you have any known medical Condition?',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'no', value: 'No' },
          ],
        },
        {
          question:
            'c) Are you currently taking any medication regularly or as required?',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'no', value: 'No' },
          ],
        },
      ],
      ippProductCode: 2013106,
    };
  },
  computed: {
    ...mapState({
      clientIdState: (state) => state.quote.clientId,
    }),
    ...mapGetters('quote', ['getProductCode']),
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'health_questionnaire_page_visited_by_agent', {
        event_category: 'health questionnaire component created',
        event_label: 'health questionnaire component created',
      });
    }
  },
  methods: {
    activateLien() {
      this.$emit('to-lien-form');
    },
    closeDialog() {
      this.existingPolicyDialog = false;
    },
  },
};
</script>
