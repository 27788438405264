<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="/images/icons/elimu-b.png"
          >Medical Checkup
        </card-title>
        <back-nav to="HealthQuestionnaire" />

        <div class="tw-w-full tw-flex tw-flex-col mt-15">
          <img
            class="tw-w-1/2 tw-self-center tw-py-5"
            src="/images/lien-doctor.png"
            alt="lien"
          />
          <p class="tw-self-center tw-w-1/2">
            Based on the client’s
            <span class="primary--text">Age and the proposed sum assured</span>
            , they are required to go for a medical checkup, below are their
            options.
          </p>
          <v-radio-group
            v-model="lienFormOption"
            class="my-2 tw-px-0 md:tw-px-6 tw-self-center tw-w-1/2"
            :rules="[required('')]"
            row
          >
            <v-radio
              label="Go for Check up"
              value="Y"
              class="tw-mt-0 md:tw-mt-5 tw-pr-10"
            ></v-radio>
            <v-radio
              label="No, Thanks"
              value="N"
              class="tw-mt-0 md:tw-mt-5"
            ></v-radio>
          </v-radio-group>
          <div
            class="tw-self-center tw-w-1/2 mt-5"
            v-if="lienFormOption === 'N'"
          >
            <p class="tw-italic tw-text-xs secondary--text">
              Policy will be issued on lien option i.e Incase of a claim reduced
              sum assured will be used as shown below
            </p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">Year ('s)</th>
                    <th scope="col" class="text-left">Value (KES)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in sumAssured" :key="i">
                    <td class="primary--text">{{ item.qnmsoYearFrom }}</td>
                    <td>{{ item.qnmsoAmount | formatNum }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <div class="tw-w-full tw-py-3 md:tw-py-6">
          <div class="tw-flex tw-flex-wrap">
            <v-btn
              class="secondary tw-w-64 mx-auto tw-my-8"
              large
              @click="lienSubmission"
              >Continue</v-btn
            >
          </div>
        </div>

        <lien-feedback
          @activate-proposal-form="proceed"
          @close-lien-dialog="closeDialog"
          :lienDialog="lienDialog"
        />
      </v-card>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CardTitle from '@/components/CardTitle';
import LienFeedback from '@/components/buy/LienFeedback';
import kycCollection from '@/mixins/kycCollectionMixins.js';
import BackNav from '../BackNav';
import helpers from '@/utils/helpers';

import { formatDate } from '@/utils/time';
import validations from '@/utils/validations';

export default {
  name: 'LienForm',
  metaInfo: {
    title: 'Buy Cover - Lien Form',
  },
  components: {
    CardTitle,
    LienFeedback,
    BackNav,
  },
  mixins: [kycCollection],

  data() {
    return {
      pageLoading: false,
      isValid: false,
      lienDialog: false,
      lienFormOption: '',
      menu: false,
      beneficiaryStatus: false,
      ...validations,
      sumAssured: [],
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'lien_form_page_visited_by_agent', {
        event_category: 'lien component created',
        event_label: 'lien component created',
      });
    }
  },

  mounted() {
    axios
      .get(
        `/lmsagent/quotations/findQuoteLienOptions?QuoteCode=${this.quoteCodeState}`,
      )
      .then((res) => {
        this.sumAssured = res.data.data;
      });
  },

  methods: {
    lienSubmission() {
      this.$store.commit('quote/lienOption', this.lienFormOption);
      this.lienDialog = true;
    },
    closeDialog() {
      this.lienDialog = false;
    },
    formatDate,
    proceed() {
      this.$emit('to-proposal-summary');
    },
  },
  filters: {
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
</script>
