<template>
  <div>
    <div class="tw-w-full tw-flex tw-justify-end">
      <v-btn class="success tw-w-64 tw-my-8" @click="dialog = !dialog">
        <v-icon class="tw-mr-2" dense>mdi-plus</v-icon> Beneficiary</v-btn
      >
    </div>

    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-form ref="form" v-model="valid">
          <div class="tw-flex tw-flex-row tw-justify-between">
            <card-title class="my-7 ml-5" icon="/images/icons/elimu-b.png"
              >Beneficiary Details
            </card-title>
            <v-btn text class="tw-mt-5" @click="dialog = !dialog">
              <v-icon class="primary--text">mdi-close</v-icon>
            </v-btn>
          </div>

          <v-container fluid class="mx-0">
            <v-row class="mx-2">
              <v-col class="align-center justify-space-between" cols="12">
                <v-row align="center" class="mr-0">
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      id="firstName"
                      label="Surname"
                      v-model="form.surname"
                      :rules="[required('Surname')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      id="OtherNames"
                      label="Other Names"
                      v-model="form.lastname"
                      :rules="[required('Last name')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dateOfBirth"
                          persistent-hint
                          readonly
                          label="Date Of Birth"
                          v-bind="attrs"
                          v-on="on"
                          class="md:tw-mx-2"
                          :rules="[required('Date of Birth')]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :active-picker.sync="activePicker"
                        v-model="form.dateOfBirth"
                        no-title
                        @input="menu = false"
                        :max="givenYearsAgo(0)"
                        :min="givenYearsAgo(100)"
                        color="primary"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row align="center" class="mr-0">
                  <v-col cols="12" sm="12" md="4">
                    <h2 class="pb-4">Gender:</h2>
                    <v-radio-group
                      v-model="form.gender"
                      :rules="[required('Gender')]"
                      class="pl-5"
                      row
                    >
                      <v-radio label="Male" value="male"></v-radio>
                      <v-radio label="Female" value="female"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      type="number"
                      label="Percentage to Beneficiary"
                      v-model="form.percentage"
                      :rules="[
                        required('Percentage to Beneficiary'),
                        max(form.percentage, this.remainingPercentage),
                        min(form.percentage, 1),
                        numberFormat(),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="getAge >= 17" fluid cols="12" sm="12" md="4">
                    <v-text-field
                      id="idNumber"
                      label="ID/Passport Number"
                      v-model="form.idNumber"
                      :rules="[required('ID/Passport Number')]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" class="mr-0">
                  <v-col v-if="getAge >= 17" cols="12" sm="12" md="6">
                    <vue-tel-input-vuetify
                      v-bind="phoneNumberBindProps"
                      v-model="phoneNumber"
                      class="my-2 tw-px-0 md:tw-px-6"
                      :rules="[required('Mobile No.')]"
                      label="Mobile No."
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-select id="relationship" v-model="form.relationship" class="md:tw-mx-5" :items="beneficiariesList"
                      label="Relationship to the policy Holder" :rules="[required('Relationship to the policy Holder')]">
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="getAge <= 17" fluid class="mx-0 px-0">
            <div class="my-10 guadian accent">
              <v-card-title class="primary--text py-7 pl-7">
                Guardian Details (if beneficiary is a minor)
              </v-card-title>
              <v-card-text>
                <p class="md:tw-w-1/2 pl-7">
                  <span class="primary--text"> Please Note,</span> the
                  relationship of the guardian is to the policy holder and not
                  the minor in mention.
                </p>
              </v-card-text>
              <v-row align="center" class="mx-4">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    id="fullname"
                    label="Full Names"
                    v-model="form.guardian.fullname"
                    :rules="[required('fullname')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    id="idNumber"
                    label="ID/Passport Number"
                    v-model="form.guardian.idNumber"
                    :rules="[required('ID/Passport Number')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <vue-tel-input-vuetify
                  v-bind="phoneNumberBindProps"
                      v-model="form.guardian.mobileNumber"
                      class="my-2 tw-px-0 md:tw-px-6"
                      :rules="[required('Mobile No.')]"
                      label="Mobile No."
                    />
                </v-col>
              </v-row>
              <v-row align="center" class="mx-4">
                <v-col cols="12" sm="12" md="4">
                  <v-select id="policyHolder" v-model="form.guardian.policyHolder" class="" :items="beneficiariesList"
                    label="Relationship to the policy Holder" :rules="[required('Relationship to the policy Holder')]">
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-container>

          <v-card-actions>
            <div class="ma-auto pb-5">
              <v-btn
                id="inviteUserBtn"
                class="ma-5 pa-4"
                color="secondary"
                @click="saveBeneficiary"
                :disabled="!valid"
              >
                Save Beneficiary
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>

              <v-btn
                id="clearFormBtn"
                class="ma-5 pa-4"
                color=""
                @click="clear"
                dark
              >
                Clear
                <v-icon dark right class="ml-2"> mdi-format-clear </v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validation from '@/utils/validations';
import { formatDate, givenYearsAgo, age } from '@/utils/time';
import CardTitle from '@/components/CardTitle';
import { beneficiaryList } from '@/utils/beneficiaries';

export default {
  name: 'BeneficiaryDetails',
  components: {
    CardTitle,
  },
  props: {
    remainingPercentage: { type: Number, default: 0, required: true },
  },
  data: () => ({
    menu: false,
    date: '',
    beneficiariesList: beneficiaryList(),
    form: {
      guardian: {},
      dateOfBirth: '',
    },
    valid: false,
    dialog: false,
    dialogInvite: false,
    alert: false,
    countryCode: null,
    activePicker: null,
    ...validation,
    phoneNumber: '',
  }),

  watch: {
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    phoneNumber() {
      this.form.mobileNumber = this.phoneNumber ? this.phoneNumber.replace(/\s/g, '') : '';
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  computed: {
    computedDateFormatted() {
      return this.form.coverFrom || this.formatDate(this.date);
    },
    getAge() {
      if (this.form.dateOfBirth) {
        return age(this.form.dateOfBirth);
      }
      return 19;
    },
  },

  methods: {
    saveBeneficiary() {
      this.$store.commit('quote/beneficiariesInfo', this.form);
      this.form = {
        guardian: {},
        dateOfBirth: '',
      };
      this.dialog = false;
    },
    formatDate,
    givenYearsAgo,
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
