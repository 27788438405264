<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <top-card current="Beneficiaries" cover="Elimu Plan" :amount="5"
        >Kindly provide some additional details before you proceed</top-card
      >

      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="/images/icons/elimu-b.png"
          >Add Beneficiary
        </card-title>
        <back-nav to="PersonalDetails" />

        <beneficiary-details
          v-if="remainingPercentage > 0"
          :remaining-percentage="remainingPercentage"
        />

        <div v-if="!beneficiaries.length <= 0">
          <v-data-table
            :headers="headers"
            :items="beneficiaries"
            :items-per-page="5"
            class="elevation-1 mt-12"
          >
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="1000px">
                <v-card>
                  <v-form ref="form" v-model="valid">
                    <div class="tw-flex tw-flex-row tw-justify-between">
                      <card-title
                        class="my-7 ml-5"
                        icon="/images/icons/elimu-b.png"
                        >Edit Beneficiary Details
                      </card-title>
                      <v-btn text class="tw-mt-5" @click="dialog = !dialog">
                        <v-icon class="primary--text">mdi-close</v-icon>
                      </v-btn>
                    </div>

                    <v-container fluid class="mx-0">
                      <v-row class="mx-2">
                        <v-col
                          class="align-center justify-space-between"
                          cols="12"
                        >
                          <v-row align="center" class="mr-0">
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                id="fullNames"
                                label="Full Names"
                                v-model="editedItem.quoBenName"
                                :rules="[required('fullname')]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template #activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.quoBenDateOfBirth"
                                    persistent-hint
                                    readonly
                                    label="Date Of Birth"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="md:tw-mx-2"
                                    :rules="[required('Date of Birth')]"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  ref="picker"
                                  v-model="editedItem.quoBenDateOfBirth"
                                  no-title
                                  @input="menu = false"
                                  :max="givenYearsAgo(0)"
                                  :min="givenYearsAgo(100)"
                                  color="primary"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row align="center" class="mr-0">
                            <v-col cols="12" sm="12" md="4">
                              <h2 class="pb-4">Gender:</h2>
                              <v-radio-group
                                :rules="[required('Gender')]"
                                class="pl-5"
                                row
                              >
                                <v-radio label="Male" value="male"></v-radio>
                                <v-radio
                                  label="Female"
                                  value="female"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                              <v-text-field
                                type="number"
                                label="Percentage to Beneficiary"
                                v-model="editedItem.quoBenPerc"
                                :rules="[
                                  required('Percentage to Beneficiary'),
                                  numberFormat(),
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col  v-if="getAge >= 17" cols="12" sm="12" md="4">
                              <v-text-field
                                id="idNumber"
                                label="ID/Passport Number"
                                v-model="editedItem.quoBenIdNo"
                                :rules="[required('ID/Passport Number')]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center" class="mr-0">
                            <v-col v-if="getAge >= 17" cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="editedItem.quoBenTel"
                                  class="my-2 tw-px-0 md:tw-px-6"
                                  label="Mobile No."
                                  type="tel"
                                  :rules="[required('Mobile No.')]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-select id="quoBenRelType" v-model="editedItem.quoBenRelType" class="md:tw-mx-5" :items="beneficiariesList"
                                label="Relationship to the policy Holder" :rules="[required('Relationship to the policy Holder')]">
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container v-if="getAge <= 17" fluid class="mx-0 px-0">
                      <div class="my-10 guadian accent">
                        <v-card-title class="primary--text py-7 pl-7">
                          Guardian Details (if beneficiary is a minor)
                        </v-card-title>
                        <v-card-text>
                          <p class="md:tw-w-1/2 pl-7">
                            <span class="primary--text"> Please Note,</span> the
                            relationship of the guardian is to the policy holder
                            and not the minor in mention.
                          </p>
                        </v-card-text>
                        <v-row align="center" class="mx-4">
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              id="fullname"
                              prepend-icon="mdi-account"
                              label="Full Names"
                              v-model="editedItem.quoBenGuardianName"
                              :rules="[required('fullname')]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              id="idNumber"
                              prepend-icon="mdi-account"
                              label="ID/Passport Number"
                              v-model="editedItem.quoBenGuardianIdNo"
                              :rules="[required('ID/Passport Number')]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="editedItem.quoBenGuardianTel"
                              prepend-icon="mdi-phone"
                              label="Phone No."
                              type="tel"
                              :rules="[required('Mobile No.')]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center" class="mx-4">
                          <v-col cols="12" sm="12" md="4">
                            <v-select id="quoBenGuardianRelType" v-model="editedItem.quoBenGuardianRelType" class="md:tw-mx-5" :items="beneficiariesList"
                              label="Relationship to the policy Holder" :rules="[required('Relationship to the policy Holder')]">
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>

                    <v-card-actions>
                      <div class="ma-auto pb-5">
                        <v-btn
                          id="inviteUserBtn"
                          class="ma-5 pa-4"
                          color="secondary"
                          @click="updateBeneficiary"
                          :disabled="!valid"
                        >
                          Save Beneficiary
                          <v-icon dark right>
                            mdi-checkbox-marked-circle
                          </v-icon>
                        </v-btn>

                        <v-btn
                          id="clearFormBtn"
                          class="ma-5 pa-4"
                          color=""
                          @click="close"
                          dark
                        >
                          Cancel
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.quoBenPerc`]="{ item }">
              {{ item.quoBenPerc }} %
            </template>
            <template v-slot:[`item.quoBenGuardianName`]="{ item }">
              <v-chip
                v-if="item.quoBenGuardianName !== 'string'"
                class="primary"
                small
                >{{ item.quoBenGuardianName }}</v-chip
              >
              <v-chip v-else class="accent primary--text" small>Adult</v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="tw-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      color="secondary"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
          <div class="flex my-6">
            <v-chip
              v-if="totalPercentage < 100"
              class="float-left pa-4 bg-gradient-blue rounded primary--text"
            >
              <v-icon class="tw-mr-2 primary--text" small dense
                >mdi-alert</v-icon
              >
              Beneficiary allocation should add upto 100%
            </v-chip>
            <h3 class="float-right primary--text">
              total percentage {{ totalPercentage }} %
            </h3>
          </div>
        </div>
        <div v-else class="tw-w-full tw-flex tw-flex-col mt-15">
          <img
            class="tw-w-45 tw-self-center tw-py-5"
            src="/images/icons/beneficiaries.png"
            alt="icon"
          />
          <p class="tw-self-center tw-w-1/2">
            <span class="primary--text">To Add Beneficiaries</span>
            please click the green button at the top right corner also,
            important to remember that Beneficiaries should add upto 100%
          </p>
        </div>

        <div class="tw-px-4 md:tw-px-12 tw-mt-4 md:tw-mt-10"></div>
        <div class="container tw-w-full tw-py-3 md:tw-py-6">
          <div class="tw-flex tw-flex-wrap">
            <v-btn
              class="secondary tw-w-64 mx-auto tw-my-8"
              :disabled="remainingPercentage > 0"
              large
              @click="proceed"
              :loading="loading"
              >Continue</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import BeneficiaryDetails from '@/components/buy/BeneficiaryDetails';
import BackNav from '../BackNav';

import validations from '@/utils/validations';
import { age, formatDate, givenYearsAgo } from '@/utils/time';
import { beneficiaryList } from '@/utils/beneficiaries';

export default {
  name: 'Beneficiaries',
  metaInfo: {
    title: 'Buy Cover - Beneficiaries',
  },
  components: {
    CardTitle,
    TopCard,
    BeneficiaryDetails,
    BackNav,
  },
  data() {
    return {
      date: '',
      beneficiariesList: beneficiaryList(),
      pageLoading: false,
      valid: false,
      dialog: false,
      menu: false,
      beneficiaryStatus: true,
      ...validations,
      editedIndex: -1,
      initialEditedItemPercentage: 0,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'quoBenName',
        },
        { text: 'DOB', value: 'quoBenDateOfBirth' },
        { text: 'ID No', value: 'quoBenIdNo' },
        // { text: 'Gender', value: 'gender' },
        { text: 'Phone', value: 'quoBenTel' },
        { text: 'Percentage', value: 'quoBenPerc' },
        { text: 'Relationship', value: 'quoBenRelType' },
        { text: 'Guardian', value: 'quoBenGuardianName' },
        { text: 'Action', value: 'action' },
      ],
      editedItem: {
        guardian: {},
        dateOfBirth: '',
      },
      defaultItem: {
        guardian: {},
        dateOfBirth: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters('quote', ['beneficiaries']),

    totalPercentage() {
      return this.beneficiaries.reduce(
        // eslint-disable-next-line radix
        (result, item) => result + parseInt(item.quoBenPerc),
        0,
      );
    },

    remainingPercentage() {
      return 100 - this.totalPercentage;
    },

    getAge() {
      if (this.editedItem && this.editedItem.quoBenDateOfBirth) {
        return age(this.editedItem.quoBenDateOfBirth);
      }
      return 19;
    },
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'add_beneficiary_page_visited_by_agent', {
        event_category: 'add beneficiary page component created',
        event_label: 'add beneficiary page component created',
      });
    }
  },

  methods: {
    formatDate,
    proceed() {
      this.loading = true;
      const benData = this.beneficiaries;
      this.$store.dispatch('quote/saveBeneficiaries', { benData });
      this.$emit('to-health-questionnaire');
      this.loading = false;
    },

    editItem(item) {
      this.editedIndex = this.beneficiaries.indexOf(item);
      this.initialEditedItemPercentage = item.quoBenPerc;
      this.editedItem = {
        ...{},
        ...item,
      };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.beneficiaries.indexOf(item);
      // eslint-disable-next-line no-restricted-globals,no-alert
      confirm('Are you sure you want to delete this item?');
      this.beneficiaries.splice(index, 1);
      this.$store.commit('quote/updateBeneficiaries', this.beneficiaries);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    updateBeneficiary() {
      if (this.editedIndex > -1) {
        this.beneficiaries.splice(this.editedIndex, 1, this.editedItem);
        this.$store.commit('quote/updateBeneficiaries', this.beneficiaries);
      }
      this.close();
    },
    givenYearsAgo,
  },
};
</script>
<style scoped>
.bg-gradient-blue {
  background: #7b91b0;
}
</style>
