<template>
  <default>
    <div class="tw-w-full" id="myDiv">
      <transition name="fade" mode="out-in" appear :duration="500">
        <keep-alive>
          <component
            v-bind:is="current"
            @to-owner-details="toOwnerDetails"
            @to-beneficiaries="toBeneficiaries"
            @to-health-questionnaire="toHealthQuestionnaire"
            @to-lien-form="toLienForm"
            @to-proposal-summary="toProposalSummary"
            @to-declaration-form="toDeclarationForm"
            @to-otp-confirmation="toOtpConfirmation"
            @to-payment-option="toPaymentOptions"
            v-bind="data"
          ></component>
        </keep-alive>
      </transition>
    </div>
  </default>
</template>

<script>
import Default from '@/layouts/Default';
import PersonalDetails from '@/components/buy/PersonalDetails';
import OwnerDetails from '@/components/buy/OwnerDetails';
import Beneficiaries from '@/components/buy/Beneficiaries';
import HealthQuestionnaire from '@/components/buy/HealthQuestionnaire';
import LienForm from '@/components/buy/LienForm';
import ProposalSummary from '@/components/buy/ProposalSummary';
import DeclarationForm from '@/components/buy/DeclarationForm';
import OtpConfirmation from '@/components/buy/OtpConfirmation';
import PaymentOptions from '@/components/buy/PaymentOptions';
import savedQuoteMixin from '../../mixins/savedQuoteMixin';

export default {
  name: 'BuyCover',
  mixins: [savedQuoteMixin],
  components: {
    Default,
    PersonalDetails,
    OwnerDetails,
    Beneficiaries,
    HealthQuestionnaire,
    LienForm,
    ProposalSummary,
    DeclarationForm,
    OtpConfirmation,
    PaymentOptions,
  },
  data: () => ({
    current: PersonalDetails,
    data: {},
  }),
  metaInfo: {
    title: 'Buy Cover',
  },
  mounted() {
    this.$root.$on('nav-back-to', (data) => {
      this.current = data.currentTab;
    });
    this.checkSavedQuote();
  },

  methods: {
    toOwnerDetails() {
      this.current = OwnerDetails;
    },
    toBeneficiaries() {
      this.current = Beneficiaries;
    },
    toHealthQuestionnaire() {
      this.current = HealthQuestionnaire;
    },
    toLienForm() {
      this.current = LienForm;
    },
    toProposalSummary() {
      this.current = ProposalSummary;
    },
    toDeclarationForm() {
      this.current = DeclarationForm;
    },
    toOtpConfirmation() {
      this.current = OtpConfirmation;
    },
    toPaymentOptions() {
      this.current = PaymentOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  & > * {
    transition-duration: 100ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.6, 0.15, 0.35, 0.8);
  }
}

$delay: 60ms;
$delayStep: 60ms;

.fade-enter,
.fade-leave-to {
  & > * {
    opacity: 0;
    transform: translateY(40px);
  }
}
.fade-enter-active {
  & > * {
    &:nth-child(2) {
      transition-delay: $delay;
    }
    &:nth-child(3) {
      transition-delay: $delay + $delayStep;
    }
  }
}
.fade-leave-active {
  & > * {
    &:nth-child(1) {
      transition-delay: $delay + $delayStep;
    }
    &:nth-child(2) {
      transition-delay: $delay;
    }
  }
}
</style>
